var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var MJ=function(a){var b=new $CLJS.Ba;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.q.g($CLJS.A(a))),a=$CLJS.C(a);else return b.toString()},NJ=function(a){var b=$CLJS.Mb($CLJS.wd(function(c,d,e){return $CLJS.xf.j(c,$CLJS.yg.g?$CLJS.yg.g(d):$CLJS.yg.call(null,d),e)},$CLJS.Kb($CLJS.ie),a));return $CLJS.J(b,$CLJS.Sc(a))},PJ=function(a){var b=OJ;return $CLJS.J($CLJS.Mb($CLJS.wd(function(c,d,e){return $CLJS.xf.j(c,d,b.g?b.g(e):b.call(null,e))},null!=a&&(a.M&4||$CLJS.gc===a.Ac)?$CLJS.Kb(a):
$CLJS.Kb($CLJS.ie),a)),$CLJS.Sc(a))},SJ=function(a){var b=QJ;if("string"===typeof a)return RJ(b,a);throw new TypeError("re-seq must match against a string.");},TJ=function(a){return new $CLJS.Ld(null,function(){return $CLJS.Jd(a.v?a.v():a.call(null),TJ(a))},null)},UJ=function(a,b){return new $CLJS.Ld(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c),e=a.g?a.g(d):a.call(null,d),f=$CLJS.Jd(d,$CLJS.lg(function(g){return $CLJS.E.h(e,a.g?a.g(g):a.call(null,g))},$CLJS.C(c)));return $CLJS.Jd(f,UJ(a,
new $CLJS.Ld(null,function(){return $CLJS.Ih($CLJS.D(f),c)},null)))}return null},null)},VJ=function(a,b,c){var d=$CLJS.PC(a,/\{0\}/);d=$CLJS.E.h($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.og(/.*\{0\}.*/,a);return $CLJS.tC.ngettext($CLJS.tC.msgid($CLJS.Fh(d),$CLJS.p(a)?c:""),b,c)},WJ=function(a,b){switch(a){case "minutes":return VJ("Minute","Minutes",b);case "hours":return VJ("Hour","Hours",b);case "days":return VJ("Day","Days",b);case "weeks":return VJ("Week","Weeks",b);case "months":return VJ("Month",
"Months",b);case "quarters":return VJ("Quarter","Quarters",b);case "years":return VJ("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));}},XJ=function(a,b,c){b=parseInt(b);c=WJ(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.E.h(b,1)],null);if($CLJS.E.h(new $CLJS.P(null,2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.$C("Previous {0}",$CLJS.G([c]));if($CLJS.E.h(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.$C("Previous {0} {1}",$CLJS.G([b,c]));if($CLJS.E.h(new $CLJS.P(null,
2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.$C("Next {0}",$CLJS.G([c]));if($CLJS.E.h(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.$C("Next {0} {1}",$CLJS.G([b,c]));throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));},ZJ=function(a){return $CLJS.SC(a,YJ,function(b){return["\\",$CLJS.q.g(b)].join("")})},$J=function(a,b,c,d,e){this.tag=a;this.source=b;this.C=c;this.l=d;this.B=e;this.A=2230716170;this.M=139264},bK=function(a){var b=$CLJS.OC(a,aK),c=$CLJS.xg.h($CLJS.A,SJ(a)),
d=function(){var e=$CLJS.D(b),f=$CLJS.D(c);return e>f?e:f}();a=$CLJS.th.h(b,$CLJS.No(d-$CLJS.D(b),TJ($CLJS.Fo(""))));d=$CLJS.th.h(c,$CLJS.No(d-$CLJS.D(c),TJ($CLJS.Fo(""))));a=$CLJS.rG.h(a,d);return $CLJS.xg.h(function(e){var f=$CLJS.ng(QJ,e);return $CLJS.p(f)?($CLJS.I(f,0,null),f=$CLJS.I(f,1,null),new $J(f,e,null,null,null)):e},a)},cK=function(a){return $CLJS.Ai(function(b){return"string"===typeof $CLJS.A(b)?new $CLJS.P(null,1,5,$CLJS.Q,[MJ(b)],null):b},$CLJS.G([UJ(function(b){return"string"===typeof b||
$CLJS.La($CLJS.Yn.g(b))},a)]))},eK=function(a,b,c){return $CLJS.xg.h(function(d){if(d instanceof $J){var e=$CLJS.Cg.j;var f=$CLJS.fh.g(d),g=$CLJS.K.h(a,f);f=$CLJS.Yn.g(g);g=$CLJS.xn.g(g);if($CLJS.p(f))try{var l=ZJ(dK.j(g,f,b))}catch(k){if(k instanceof Error)l=dK.j($CLJS.bt,f,b);else throw k;}else l=null;d=e.call($CLJS.Cg,d,$CLJS.Yn,l)}return d},c)},hK=function(a){a=MJ($CLJS.xg.h(function(b){return b instanceof $J?b:$CLJS.SC(b,fK,"")},a));return $CLJS.SC(a,gK,$CLJS.Mc)},OJ=function(a){return NJ($CLJS.A(iK(new $CLJS.P(null,
1,5,$CLJS.Q,[$CLJS.dr],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))))},RJ=function RJ(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Ue(d);return $CLJS.Jd(f,new $CLJS.Ld(null,function(){var g=e.length;g=d.index+(1>g?1:g);return g<=b.length?(g=b.substring(g),RJ.h?RJ.h(a,g):RJ.call(null,a,g)):null},null))},iK=function iK(a,b){if($CLJS.La($CLJS.y(a)))a=(0,$CLJS.DJ)(b);else{var d=$CLJS.K,e=d.h;var f=$CLJS.jg(a);b=$CLJS.he([$CLJS.Nc(a),b]);f=iK.h?iK.h(f,b):iK.call(null,
f,b);a=e.call(d,f,$CLJS.Nc(a))}return a};var dK=function(){var a=$CLJS.vh.g($CLJS.ie),b=$CLJS.vh.g($CLJS.ie),c=$CLJS.vh.g($CLJS.ie),d=$CLJS.vh.g($CLJS.ie),e=$CLJS.K.j($CLJS.ie,$CLJS.XD,$CLJS.Rh.v?$CLJS.Rh.v():$CLJS.Rh.call(null));return new $CLJS.IC($CLJS.Fg.h("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.yg.g(f)},e,a,b,c,d)}();$CLJS.JC(dK,$CLJS.Ey,function(a,b,c){return(0,$CLJS.LJ)(b).locale(c).format("MMMM D, YYYY")});
$CLJS.JC(dK,$CLJS.eq,function(a,b,c){a=(0,$CLJS.LJ)(b,"YYYY-MM").locale(c);return $CLJS.p(a.isValid())?a.format("MMMM, YYYY"):""});$CLJS.JC(dK,$CLJS.at,function(a,b,c){a=(0,$CLJS.LJ)(b,"[Q]Q-YYYY").locale(c);return $CLJS.p(a.isValid())?a.format("[Q]Q, YYYY"):""});$CLJS.JC(dK,$CLJS.Tp,function(a,b,c){b=$CLJS.PC(b,/~/);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.p($CLJS.p(a)?b:a)?[$CLJS.q.g(dK.j($CLJS.Ey,a,c))," - ",$CLJS.q.g(dK.j($CLJS.Ey,b,c))].join(""):""});
$CLJS.JC(dK,$CLJS.Nt,function(a,b){function c(d,e){return $CLJS.C($CLJS.og(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.aD("Today");if(c(/^thisweek$/,b))return $CLJS.aD("This Week");if(c(/^thismonth$/,b))return $CLJS.aD("This Month");if(c(/^thisquarter$/,b))return $CLJS.aD("This Quarter");if(c(/^thisyear$/,b))return $CLJS.aD("This Year");if(c(/^past1days$/,b))return $CLJS.aD("Yesterday");if(c(/^next1days$/,b))return $CLJS.aD("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.ae(XJ,
a);throw Error(["No matching clause: ",$CLJS.q.g(b)].join(""));});$CLJS.JC(dK,$CLJS.dv,function(a,b,c){function d(e,f){return $CLJS.Mc($CLJS.og(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.p(a))return dK.j($CLJS.Nt,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.p(a))return dK.j($CLJS.Ey,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.p(a)?dK.j($CLJS.Tp,a,c):dK.j($CLJS.Nt,b,c)});
$CLJS.JC(dK,$CLJS.bt,function(a,b){return $CLJS.Wc(b)?$CLJS.E.h($CLJS.D(b),1)?$CLJS.q.g($CLJS.A(b)):$CLJS.$C("{0} and {1}",$CLJS.G([$CLJS.Io($CLJS.jg(b)),$CLJS.Nc(b)])):$CLJS.q.g(b)});var YJ=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,QJ=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,aK=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;$CLJS.h=$J.prototype;$CLJS.h.toString=function(){var a=$CLJS.Yn.g(this);return $CLJS.p(a)?a:this.source};$CLJS.h.ga=function(a,b){return this.N(null,b,null)};
$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fh,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pH,this.source],null)],null),this.l))};$CLJS.h.ia=function(){return new $CLJS.kf(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fh,$CLJS.pH],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};
$CLJS.h.ba=function(){return 2+$CLJS.D(this.l)};$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=-884441273^$CLJS.vc(this)};$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.tag,b.tag)&&$CLJS.E.h(this.source,b.source)&&$CLJS.E.h(this.l,b.l)};
$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,2,[$CLJS.pH,null,$CLJS.fh,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new $J(this.tag,this.source,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.fd(this.l,b)}};
$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.fh,b):$CLJS.O.call(null,$CLJS.fh,b))?new $J(c,this.source,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.pH,b):$CLJS.O.call(null,$CLJS.pH,b))?new $J(this.tag,c,this.C,this.l,null):new $J(this.tag,this.source,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.gd($CLJS.fh,this.tag),new $CLJS.gd($CLJS.pH,this.source)],null),this.l))};
$CLJS.h.aa=function(a,b){return new $J(this.tag,this.source,b,this.l,this.B)};$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};
var fK=/\[\[.+\]\]/,gK=/\[\[(.+?)\]\]/,jK=function jK(a){switch(arguments.length){case 2:return jK.h(arguments[0],arguments[1]);case 3:return jK.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}},kK=["metabase","shared","parameters","parameters","substitute_tags"],lK=$CLJS.fa;kK[0]in lK||"undefined"==typeof lK.execScript||lK.execScript("var "+kK[0]);
for(var mK;kK.length&&(mK=kK.shift());)kK.length||void 0===jK?lK=lK[mK]&&lK[mK]!==Object.prototype[mK]?lK[mK]:lK[mK]={}:lK[mK]=jK;jK.h=function(a,b){return jK.j(a,b,"en")};jK.j=function(a,b,c){return $CLJS.p(a)?(b=$CLJS.eH(b),b=PJ(b),hK(cK(eK(b,c,bK(a))))):null};jK.J=3;module.exports={tag_names:function(a){a=$CLJS.ig($CLJS.xg.h($CLJS.Mc,SJ($CLJS.p(a)?a:"")));return $CLJS.Fh(a)},substitute_tags:jK};