var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var qG=function(){},sG=function(a,b){return $CLJS.Ih(1,$CLJS.rG.h(new $CLJS.ze(null,-1,a,null),b))},tG=function(a){return $CLJS.K.h($CLJS.dF,a).value},vG=function(a){var b=tG(a);return $CLJS.p(function(){var c=tG($CLJS.pF);return uG.h?uG.h(c,b):uG.call(null,c,b)}())?$CLJS.lG:$CLJS.p(function(){var c=tG($CLJS.qF);return uG.h?uG.h(c,b):uG.call(null,c,b)}())?$CLJS.nG:$CLJS.p(function(){var c=tG($CLJS.Lh);return uG.h?uG.h(c,b):uG.call(null,c,b)}())?$CLJS.bG:$CLJS.p(function(){var c=tG($CLJS.xF);
return uG.h?uG.h(c,b):uG.call(null,c,b)}())?$CLJS.iG:$CLJS.p(function(){var c=tG($CLJS.mF);return uG.h?uG.h(c,b):uG.call(null,c,b)}())?$CLJS.kG:$CLJS.p(function(){var c=tG($CLJS.lF);return uG.h?uG.h(c,b):uG.call(null,c,b)}())?$CLJS.dG:$CLJS.p(function(){var c=tG($CLJS.oF);return uG.h?uG.h(c,b):uG.call(null,c,b)}())?$CLJS.gG:$CLJS.eG},wG=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.q.g(b),$CLJS.q.g(" ")].join(""),a],null)},xG=function(a,b,c){var d=
$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.q.g(d),"%c",$CLJS.q.g(b),"%c"].join(""),$CLJS.Gd.o(a,["color:",$CLJS.q.g($CLJS.K.h($CLJS.pG,c))].join(""),$CLJS.G(["color:black"]))],null)},yG=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.q.g(d),"%c",$CLJS.q.g(b),"%c"].join(""),$CLJS.Gd.o(a,["color:",$CLJS.q.g($CLJS.K.h($CLJS.pG,$CLJS.aG)),";background-color:",$CLJS.q.g($CLJS.K.h($CLJS.pG,c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],
null)},zG=function(a){function b(c,d){return d>=c}a=tG(a);if(b(tG($CLJS.pF),a))return"error";if(b(tG($CLJS.qF),a))return"warn";if(b(tG($CLJS.Lh),a))return"info";b(tG($CLJS.xF),a);return"log"},AG=function(a){return function(b){var c=$CLJS.je(b),d=$CLJS.K.h(c,$CLJS.wF),e=$CLJS.K.h(c,$CLJS.hF);b=$CLJS.K.h(c,$CLJS.jF);e=zG(e);e=$CLJS.xa(console,e);e=$CLJS.p(e)?e:console.log;$CLJS.ae(e,a.g?a.g(c):a.call(null,c));return $CLJS.p(b)?(c=["[",$CLJS.q.g(d),"]"].join(""),d=$CLJS.q.g(b),b=b.stack,e.O?e.O(c,d,
"\n",b):e.call(null,c,d,"\n",b)):null}},uG=function uG(a){switch(arguments.length){case 1:return uG.g(arguments[0]);case 2:return uG.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uG.o(arguments[0],arguments[1],new $CLJS.lc(c.slice(2),0,null))}};uG.g=function(){return!0};uG.h=function(a,b){return a<=b};uG.o=function(a,b,c){for(;;)if(a<=b)if($CLJS.C(c))a=b,b=$CLJS.A(c),c=$CLJS.C(c);else return b<=$CLJS.A(c);else return!1};
uG.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return this.o(b,a,c)};uG.J=2;$CLJS.rG=function rG(a){switch(arguments.length){case 0:return rG.v();case 1:return rG.g(arguments[0]);case 2:return rG.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return rG.o(arguments[0],arguments[1],new $CLJS.lc(c.slice(2),0,null))}};$CLJS.rG.v=function(){return $CLJS.B};
$CLJS.rG.g=function(a){return new $CLJS.Ld(null,function(){return a},null)};$CLJS.rG.h=function(a,b){return new $CLJS.Ld(null,function(){var c=$CLJS.y(a),d=$CLJS.y(b);return c&&d?$CLJS.Jd($CLJS.A(c),$CLJS.Jd($CLJS.A(d),$CLJS.rG.h($CLJS.qc(c),$CLJS.qc(d)))):null},null)};$CLJS.rG.o=function(a,b,c){return new $CLJS.Ld(null,function(){var d=$CLJS.xg.h($CLJS.y,$CLJS.Gd.o(c,b,$CLJS.G([a])));return $CLJS.re($CLJS.xd,d)?$CLJS.th.h($CLJS.xg.h($CLJS.A,d),$CLJS.ae($CLJS.rG,$CLJS.xg.h($CLJS.qc,d))):null},null)};
$CLJS.rG.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return this.o(b,a,c)};$CLJS.rG.J=2;
var BG=function BG(a,b){for(;;){if($CLJS.E.h($CLJS.cG,b))return xG(a,", ",$CLJS.eG);if($CLJS.E.h($CLJS.oG,b))return wG(a);if(b instanceof $CLJS.M)return xG(a,b,$CLJS.bG);if(b instanceof $CLJS.t)return xG(a,b,$CLJS.iG);if("string"===typeof b)return xG(a,$CLJS.Eg($CLJS.G([b])),$CLJS.hG);if($CLJS.Pe(b)){var d=wG(function(){var f=a,g=$CLJS.kb(b);return BG.h?BG.h(f,g):BG.call(null,f,g)}()),e=$CLJS.lb(b);return BG.h?BG.h(d,e):BG.call(null,d,e)}if(b instanceof $CLJS.n||b instanceof $CLJS.Xf)return d=a,d=
xG(d,"{",$CLJS.jG),d=$CLJS.qd(BG,d,sG($CLJS.cG,b)),xG(d,"}",$CLJS.jG);if($CLJS.Xc(b))return d=a,d=xG(d,["#",$CLJS.q.g(function(){var f=$CLJS.Na(b),g=f.name;return $CLJS.Tc(g)?$CLJS.Eg($CLJS.G([f])):g}())," "].join(""),$CLJS.mG),d=xG(d,"{",$CLJS.jG),d=$CLJS.qd(BG,d,sG($CLJS.cG,b)),xG(d,"}",$CLJS.jG);if($CLJS.Vc(b))return d=a,d=xG(d,"#{",$CLJS.jG),d=$CLJS.qd(BG,d,sG($CLJS.oG,b)),xG(d,"}",$CLJS.jG);if($CLJS.Zc(b))return d=a,d=xG(d,"[",$CLJS.jG),d=$CLJS.qd(BG,d,sG($CLJS.oG,b)),xG(d,"]",$CLJS.jG);if($CLJS.Hh(b))return d=
a,d=xG(d,"(",$CLJS.mG),d=$CLJS.qd(BG,d,sG($CLJS.oG,b)),xG(d,")",$CLJS.mG);if(null!=b?b.M&16384||$CLJS.gc===b.Od||(b.M?0:$CLJS.Ma(qG,b)):$CLJS.Ma(qG,b))d=xG(a,"#atom ",$CLJS.mG),e=$CLJS.pb(b),a=d,b=e;else if(null!=b&&$CLJS.gc===b.Qc)d=xG(a,"#uuid ",$CLJS.mG),e=$CLJS.q.g(b),a=d,b=e;else if(null!=b&&b.constructor===Object)d=xG(a,"#js ",$CLJS.mG),e=$CLJS.qd(function(f,g){return function(l,k){return $CLJS.Cg.j(l,$CLJS.yg.g(k),$CLJS.xa(g,k))}}(a,b),$CLJS.ie,Object.keys(b)),a=d,b=e;else if($CLJS.Ka(b))d=
xG(a,"#js ",$CLJS.mG),e=$CLJS.uf.h($CLJS.Te,b),a=d,b=e;else return xG(a,$CLJS.Eg($CLJS.G([b])),$CLJS.fG)}};$CLJS.CG=AG(function(a){a=$CLJS.je(a);$CLJS.K.h(a,$CLJS.hF);var b=$CLJS.K.h(a,$CLJS.wF),c=$CLJS.K.h(a,$CLJS.nF);$CLJS.K.h(a,$CLJS.jF);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.q.g(b),"]"].join(""),c],null)});
$CLJS.DG=AG(function(a){var b=$CLJS.je(a),c=$CLJS.K.h(b,$CLJS.hF);a=$CLJS.K.h(b,$CLJS.wF);var d=$CLJS.K.h(b,$CLJS.nF);$CLJS.K.h(b,$CLJS.jF);b=vG(c);d=BG(wG(yG(yG(yG(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Te],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Jd(a,d)});
$CLJS.EG=AG(function(a){a=$CLJS.je(a);$CLJS.K.h(a,$CLJS.hF);var b=$CLJS.K.h(a,$CLJS.wF),c=$CLJS.K.h(a,$CLJS.nF);$CLJS.K.h(a,$CLJS.jF);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.q.g(b),"]"].join(""),$CLJS.Eg($CLJS.G([c]))],null)});