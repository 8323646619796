import Settings from "metabase/lib/settings";
import { isDev, isProduction } from "metabase/env";

const eventQueue = [];

export const createTracker = () => {
  if (isTrackingEnabled()) {
    createGoogleAnalyticsTracker();
    // document.body.addEventListener("click", handleStructEventClick, true);
  }
};

export const trackPageView = (url, type) => {
  // console.log("[PAGE_VIEW]", [url, type]);
  if (isTrackingEnabled() && url) {
    trackGoogleAnalyticsPageView(url);
  }
};

export const trackStructEvent = (category, action, label, value, otherItems) => {
  // if (category && action) {
  //   trackGoogleAnalyticsStructEvent(category, action, label, value, otherItems);
  // } else if (category && !action) {
  //   trackGoogleAnalyticsStructEvent("Category", category, label, value, otherItems);
  // }
};

export const trackStructEvent2 = (category, action, label, value, otherItems) => {
  if (category && action) {
    trackGoogleAnalyticsStructEvent(category, action, label, value, otherItems);
  } else if (category && !action) {
    trackGoogleAnalyticsStructEvent("Category", category, label, value, otherItems);
  }
};

export const trackStructEventDelay = (category, action, label, value, otherItems) => {
  if (category && action) {
    trackGoogleAnalyticsStructEventDelay(category, action, label, value, otherItems);
  } else if (category && !action) {
    trackGoogleAnalyticsStructEventDelay("Category", category, label, value, otherItems);
  }
};

export const trackSchemaEvent = () => {
  // intentionally blank
};

const isTrackingEnabled = () => {
  return isProduction;
};

const createGoogleAnalyticsTracker = () => {
  // Settings.on("anon-tracking-enabled", enabled => {
  //   window[`ga-disable-${code}`] = enabled ? null : true;
  // });

  // window.gtag?.("config", "G-XBN10PCQ8H", { send_page_view: false });
};

const trackGoogleAnalyticsPageView = url => {
  // window.gtag?.("event", "page_view");
  window.dataLayer?.push({
    'event': "page_view",
  });
};

const trackGoogleAnalyticsStructEvent = (
  category,
  action = "Action",
  label = "Label",
  value = 1,
  otherItems = {},
) => {
  const version = Settings.get("version", {});
  if (isTrackingEnabled()) {
    window.dataLayer?.push({
      'event': action,
      event_category: category,
      event_label: label,
      event_value: 1,
      event_timestamp: new Date().getTime(),
      ...otherItems,
    });
    // window.gtag?.("event", action, {
    //   event_category: category,
    //   event_label: label,
    //   value,
    //   event_timestamp: new Date().getTime(),
    //   ...otherItems,
    // });
    localStorage.setItem("lastGTag", action);
  }
  console.log("[GTAG_EVENT]", [ category, action, label, value, version, otherItems ]);
};

const trackGoogleAnalyticsStructEventDelay = (
  category,
  action = "Action",
  label = "Label",
  value = 1,
  otherItems = {},
) => {
  const version = Settings.get("version", {});
  if (isTrackingEnabled()) {
    eventQueue.push({
      "event": "event",
      "action": action,
      "item": {
        event_category: category,
        event_label: label,
        value,
        event_timestamp: new Date().getTime(),
        ...otherItems,
      }
    })
    localStorage.setItem("lastGTag", action);
  }
  console.log("[GTAG_EVENT]", [ category, action, label, value, version, otherItems ]);
};

export const handleQueueEvent = () => {
  if (!isTrackingEnabled()) {
    return;
  }

  const array = [...eventQueue];
  eventQueue.length = 0;
  array?.forEach(i => {
    console.log("handleQueueEvent", i, window.gtag)
    window.dataLayer?.push({
      'event': i.action,
      ...i.item,
    });
    // window.gtag?.(i.event, i.action, i.item);
  })
};

const handleStructEventClick = event => {
  if (!isTrackingEnabled()) {
    return;
  }

  for (let node = event.target; node != null; node = node.parentNode) {
    if (node.dataset && node.dataset.metabaseEvent) {
      const parts = node.dataset.metabaseEvent.split(";").map(p => p.trim());
      // trackStructEvent(...parts);
    }
  }
};

export const init = () => {
  // if (isDev) {
  //   window.gtag?.('set', 'debug_mode', true);
  // }
};
