var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.log.js");
'use strict';var OG,PG,QG,RG,SG,TG,UG,VG,XG,ZG,$G,aH,cH,gH,hH,YG,iH,lH,mH,nH,rH,sH,uH,vH,wH,xH,yH,zH,WG,DH,EH,FH,GH,HH,LH,MH,OH,QH,RH,TH,VH,fH,dH,UH,WH,XH,qH,YH,ZH,$H,aI,bI,cI,dI,kH,eI,fI,oH,gI,hI,iI,jI,kI;OG=function(){};PG=function(){};QG=function(){};RG=function(a,b){if(null!=a&&null!=a.cd)a=a.cd(a,b);else{var c=RG[$CLJS.sa(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=RG._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Oa("IEncodeClojure.-js-\x3eclj",a);}return a};
SG=function(a){return a instanceof Error?a.message:null};TG=function(a){a:for(var b=a;;)if(b=$CLJS.y(b))b=$CLJS.C(b);else break a;return a};UG=function(a){function b(d,e){d.push(e);return d}var c=[];return $CLJS.qd?$CLJS.qd(b,c,a):$CLJS.rd.call(null,b,c,a)};
VG=function(a,b){b=$CLJS.Yc(b)?$CLJS.uf.h($CLJS.ie,b):b;return(null!=b?b.M&4||$CLJS.gc===b.Ac||(b.M?0:$CLJS.Ma(PG,b)):$CLJS.Ma(PG,b))?$CLJS.Mb($CLJS.wd(a.g?a.g($CLJS.xf):a.call(null,$CLJS.xf),$CLJS.Kb($CLJS.Oc(b)),b)):$CLJS.wd(a.g?a.g($CLJS.Cg):a.call(null,$CLJS.Cg),$CLJS.Oc(b),b)};XG=function(a){var b=WG;return VG(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};
ZG=function(a){var b=YG;return VG(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$G=function(a,b,c){var d=$CLJS.hd(a,b);if($CLJS.p(d)){var e=$CLJS.Cg.j;d=$CLJS.lb(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.Cg,a,b,c)}return a};
aH=function(a,b,c){if($CLJS.Ed(c)){var d=$CLJS.ae($CLJS.U,$CLJS.xg.h(a,c));return b.g?b.g(d):b.call(null,d)}return $CLJS.Pe(c)?(d=new $CLJS.gd(function(){var e=$CLJS.kb(c);return a.g?a.g(e):a.call(null,e)}(),function(){var e=$CLJS.lb(c);return a.g?a.g(e):a.call(null,e)}()),b.g?b.g(d):b.call(null,d)):$CLJS.Hh(c)?(d=TG($CLJS.xg.h(a,c)),b.g?b.g(d):b.call(null,d)):$CLJS.Yc(c)?(d=$CLJS.qd(function(e,f){return $CLJS.Gd.h(e,a.g?a.g(f):a.call(null,f))},c,c),b.g?b.g(d):b.call(null,d)):$CLJS.Uc(c)?(d=$CLJS.uf.h($CLJS.Oc(c),
$CLJS.xg.h(a,c)),b.g?b.g(d):b.call(null,d)):b.g?b.g(c):b.call(null,c)};cH=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yg.g(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return bH(function(c){return $CLJS.Xc(c)?$CLJS.uf.h($CLJS.ie,$CLJS.xg.h(b,c)):c},a)};
$CLJS.eH=function(a){var b=$CLJS.G([$CLJS.ah,!1]),c=$CLJS.je(b);c=$CLJS.K.h(c,$CLJS.ah);var d=$CLJS.p(c)?$CLJS.yg:$CLJS.q;return function g(f){return(null!=f?$CLJS.gc===f.Sd||(f.qc?0:$CLJS.Ma(QG,f)):$CLJS.Ma(QG,f))?RG(f,$CLJS.ae(dH,b)):$CLJS.Hh(f)?TG($CLJS.xg.h(g,f)):$CLJS.Pe(f)?new $CLJS.gd(g($CLJS.kb(f)),g($CLJS.lb(f))):$CLJS.Uc(f)?$CLJS.uf.j($CLJS.Oc(f),$CLJS.xg.g(g),f):$CLJS.Ka(f)?$CLJS.Mb($CLJS.qd(function(l,k){k=g(k);return $CLJS.Lb(l,k)},$CLJS.Kb($CLJS.Te),f)):$CLJS.Na(f)===Object?$CLJS.Mb($CLJS.qd(function(l,
k){return $CLJS.xf.j(l,d.g?d.g(k):d.call(null,k),g($CLJS.xa(f,k)))},$CLJS.Kb($CLJS.ie),$CLJS.wa(f))):f}(a)};gH=function(a,b,c){if($CLJS.p(!1)){var d=$CLJS.XE($CLJS.$E("metabase.mbql.normalize"));$CLJS.p(d)&&(a=$CLJS.K.h($CLJS.dF,a),b=new ("undefined"!==typeof $CLJS.ea&&"undefined"!==typeof $CLJS.gF&&"undefined"!==typeof $CLJS.gF.Yc?$CLJS.gF.Yc:fH)(a,b,"metabase.mbql.normalize"),$CLJS.p(c)&&(b.Fc=c),"undefined"!==typeof $CLJS.ea&&"undefined"!==typeof $CLJS.aF&&"undefined"!==typeof OG||d.de(b))}};
hH=function(a){var b=$CLJS.Wc(a);return b?(b=!$CLJS.Pe(a))?$CLJS.yh.h($CLJS.qm,$CLJS.km)($CLJS.A(a)):b:b};YG=function(a){return $CLJS.p($CLJS.yh.h($CLJS.qm,$CLJS.km)(a))?$CLJS.hE(a):a};iH=function(a,b){var c=hH(b);return $CLJS.p(c)?(b=YG($CLJS.A(b)),$CLJS.Uc(a)?(a=$CLJS.ig(a),a.g?a.g(b):a.call(null,b)):$CLJS.E.h(a,b)):c};
lH=function(a){return $CLJS.K.j(new $CLJS.n(null,3,[$CLJS.bt,$CLJS.xd,$CLJS.xn,YG,$CLJS.pv,YG],null),a,function(b){var c=$CLJS.K,d=c.h;b=$CLJS.he([a,b]);b=jH.h?jH.h(b,kH):jH.call(null,b,kH);return d.call(c,b,a)})};
mH=function(a){a=$CLJS.uf.j($CLJS.ie,$CLJS.xg.g(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=YG(c);var d=lH(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.E.h($CLJS.xn.g(a),$CLJS.qx)&&$CLJS.La($CLJS.pv.g(a))?$CLJS.Cg.j(a,$CLJS.pv,$CLJS.ku):a};
nH=function(a){return $CLJS.uf.j($CLJS.ie,$CLJS.xg.g(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.bD(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.Cg.j(mH(b),$CLJS.T,c)],null)}),a)};rH=function(a){return $G($G($CLJS.qd(function(b,c){return $G(b,c,$CLJS.yg)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.lj,$CLJS.$i,$CLJS.Wy,oH,$CLJS.pH,$CLJS.Ly],null)),qH,$CLJS.Ji.h(WG,jH)),$CLJS.Cq,cH)};sH=function(a){return $CLJS.dd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,a,null],null):a};uH=function(a){return tH.g(sH(a))};
vH=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.C(b);return $CLJS.fD($CLJS.uf.h(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.xg.h(tH,b)))};wH=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.C(b);b=$CLJS.A(c);c=$CLJS.C(c);return $CLJS.uf.h(new $CLJS.P(null,2,5,$CLJS.Q,[a,uH(b)],null),$CLJS.xg.h(tH,c))};xH=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.p(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,uH(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
yH=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,uH(a)],null)};zH=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,tH.g(a)],null)};
WG=function(a){return AH(function(b){if($CLJS.Xc(b))return XG(b);if($CLJS.La(hH(b)))return b;try{return tH.g(b)}catch(f){if(f instanceof Error){var c=f;var d=$CLJS.XE($CLJS.$E("metabase.mbql.normalize")).isLoggable($CLJS.dF.g?$CLJS.dF.g($CLJS.Jh):$CLJS.dF.call(null,$CLJS.Jh));if($CLJS.p(d))if(d=$CLJS.G([$CLJS.aD("Invalid clause:"),b]),$CLJS.A(d)instanceof Error){var e=$CLJS.y(d);d=$CLJS.A(e);e=$CLJS.C(e);gH($CLJS.Jh,$CLJS.ae(BH,e),d)}else d=$CLJS.ae(BH,d),gH($CLJS.Jh,d,null);throw $CLJS.Go($CLJS.$C("Invalid MBQL clause: {0}",
$CLJS.G([SG(c)])),new $CLJS.n(null,1,[$CLJS.Wq,b],null),c);}throw f;}},a)};DH=function(a){return $CLJS.Ue($CLJS.ve(WG,CH(a)))};EH=function(a){for(;;)if($CLJS.p(hH(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.ke($CLJS.Ah.h(sH,a))};FH=function(a){a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.qx);return $CLJS.p(b)?$CLJS.wD.j(a,$CLJS.qx,tH):a};
GH=function(a){return $CLJS.uf.h($CLJS.ie,function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);r=new $CLJS.P(null,2,5,$CLJS.Q,[r,FH(m)],null);l.add(r);k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.Jd(new $CLJS.P(null,
2,5,$CLJS.Q,[l,FH(f)],null),d($CLJS.qc(e)))}return null}},null)}(a)}())};HH=function(a){a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.jq);return $CLJS.p(b)?$CLJS.wD.j(a,$CLJS.jq,GH):a};LH=function(a){var b=$CLJS.je(a);a=$CLJS.K.h(b,$CLJS.wx);b=$CLJS.La(a)?KH.g?KH.g(b):KH.call(null,b):b;return $CLJS.p(a)?HH(b):b};MH=function(a){return $CLJS.Uc(a)?$CLJS.y(a):null!=a};
OH=function(a){var b=$CLJS.je(a);a=$CLJS.K.h(b,$CLJS.Ux);var c=$CLJS.K.h(b,$CLJS.dr),d=$CLJS.K.h(b,$CLJS.Ks),e=$CLJS.K.h(b,$CLJS.wx);try{if($CLJS.p(d)){var f=$CLJS.je(b),g=$CLJS.K.h(f,$CLJS.Ks);var l=NH($CLJS.sh.h(f,$CLJS.Ks),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ux,$CLJS.Ks],null),g)}else l=b;var k=$CLJS.p(a)?$CLJS.wD.j(l,$CLJS.Ux,KH):l,m=$CLJS.p(c)?$CLJS.wD.j(k,$CLJS.dr,$CLJS.ui($CLJS.Ah,WG)):k,r=$CLJS.p(e)?$CLJS.wD.j(m,$CLJS.wx,HH):m;return WG(r)}catch(v){if(v instanceof Error)throw k=v,$CLJS.Go($CLJS.$C("Error canonicalizing query: {0}",
$CLJS.G([SG(k)])),new $CLJS.n(null,1,[$CLJS.Ux,a],null),k);throw v;}};
QH=function(a){a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.Ux),c=$CLJS.je(b);b=$CLJS.K.h(c,$CLJS.cw);c=$CLJS.K.h(c,$CLJS.wy);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.ig($CLJS.ud($CLJS.th,$CLJS.y($CLJS.Be($CLJS.Bo,function g(e,f){try{if($CLJS.Zc(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.O(l,$CLJS.px)){var k=$CLJS.F(f,1),m=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,k,$CLJS.sh.h(m,$CLJS.Pw)],null)],null)],null)}throw $CLJS.Z;
}catch(r){if(r instanceof Error){l=r;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw r;}else throw $CLJS.Z;}catch(r){if(r instanceof Error){l=r;if(l===$CLJS.Z)return $CLJS.YC(g,e,f);throw l;}throw r;}}($CLJS.Te,b))))),PH.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ux,$CLJS.wy],null),$CLJS.Ji.h($CLJS.Ue,$CLJS.ui($CLJS.zh,b)))):a};
RH=function(a){try{return QH(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Go($CLJS.aD("Error performing whole query transformations"),new $CLJS.n(null,1,[$CLJS.Ux,a],null),b);}throw c;}};
TH=function(a,b){var c=$CLJS.uf.h($CLJS.Oc(a),function(){return function f(e){return new $CLJS.Ld(null,function(){for(var g=e;;)if(g=$CLJS.y(g)){if($CLJS.ad(g)){var l=$CLJS.Rb(g),k=$CLJS.D(l),m=$CLJS.Od(k);return function(){for(var x=0;;)if(x<k){var z=$CLJS.Pc(l,x),H=$CLJS.I(z,0,null);z=$CLJS.I(z,1,null);var N=$CLJS.Gd.h(b,H);z=SH.h?SH.h(z,N):SH.call(null,z,N);null!=z&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[H,z],null));x+=1}else return!0}()?$CLJS.Rd($CLJS.Td(m),f($CLJS.Sb(g))):$CLJS.Rd($CLJS.Td(m),null)}var r=
$CLJS.A(g),v=$CLJS.I(r,0,null),w=$CLJS.I(r,1,null);r=function(){var x=w,z=$CLJS.Gd.h(b,v);return SH.h?SH.h(x,z):SH.call(null,x,z)}();if(null!=r)return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[v,r],null),f($CLJS.qc(g)));g=$CLJS.qc(g)}else return null},null)}(a)}());return $CLJS.y(c)?c:null};VH=function(a,b){a=$CLJS.Ah.h(function(c){var d=$CLJS.Gd.h(b,UH);return SH.h?SH.h(c,d):SH.call(null,c,d)},a);return $CLJS.p($CLJS.se($CLJS.Bo,a))?a:null};
fH=class{constructor(a,b,c,d,e){this.Fc=void 0;this.reset(a||$CLJS.ma,b,c,d,e)}reset(a,b,c,d,e){this.Ed=d||Date.now();this.Uc=a;this.yd=b;this.vd=c;this.Fc=void 0;this.Cd="number"===typeof e?e:0}Dd(a){this.Uc=a}};dH=function dH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return dH.o(0<c.length?new $CLJS.lc(c.slice(0),0,null):null)};
dH.o=function(a){a=a instanceof $CLJS.lc&&0===a.H?a.m:UG(a);if(!$CLJS.Jo(a.length))throw Error(["No value supplied for key: ",$CLJS.q.g($CLJS.Nc(a))].join(""));return $CLJS.he(a)};dH.J=0;dH.I=function(a){return this.o($CLJS.y(a))};
var NH=function NH(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.C(e);if(f){e=$CLJS.Cg.j;var g=$CLJS.K.h(a,b);c=NH.j?NH.j(g,f,c):NH.call(null,g,f,c);a=e.call($CLJS.Cg,a,b,c)}else a=$CLJS.Cg.j(a,b,c);return a},PH=function PH(a){switch(arguments.length){case 3:return PH.j(arguments[0],arguments[1],arguments[2]);case 4:return PH.O(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return PH.ea(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return PH.qa(arguments[0],
arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return PH.o(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5],new $CLJS.lc(c.slice(6),0,null))}};PH.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.C(d))a=$CLJS.Cg.j(a,b,PH.j($CLJS.K.h(a,b),d,c));else{d=$CLJS.Cg.j;var e=$CLJS.K.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.Cg,a,b,c)}return a};
PH.O=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.C(e))a=$CLJS.Cg.j(a,b,PH.O($CLJS.K.h(a,b),e,c,d));else{e=$CLJS.Cg.j;var f=$CLJS.K.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.Cg,a,b,c)}return a};PH.ea=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.C(f))a=$CLJS.Cg.j(a,b,PH.ea($CLJS.K.h(a,b),f,c,d,e));else{f=$CLJS.Cg.j;var g=$CLJS.K.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.Cg,a,b,c)}return a};
PH.qa=function(a,b,c,d,e,f){var g=$CLJS.y(b);b=$CLJS.A(g);if(g=$CLJS.C(g))a=$CLJS.Cg.j(a,b,PH.qa($CLJS.K.h(a,b),g,c,d,e,f));else{g=$CLJS.Cg.j;var l=$CLJS.K.h(a,b);c=c.O?c.O(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.Cg,a,b,c)}return a};PH.o=function(a,b,c,d,e,f,g){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.C(l))?$CLJS.Cg.j(a,b,$CLJS.ee(PH,$CLJS.K.h(a,b),l,c,d,$CLJS.G([e,f,g]))):$CLJS.Cg.j(a,b,$CLJS.ee(c,$CLJS.K.h(a,b),d,e,f,$CLJS.G([g])))};
PH.I=function(a){var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);var d=$CLJS.C(c);c=$CLJS.A(d);var e=$CLJS.C(d);d=$CLJS.A(e);var f=$CLJS.C(e);e=$CLJS.A(f);var g=$CLJS.C(f);f=$CLJS.A(g);g=$CLJS.C(g);return this.o(b,a,c,d,e,f,g)};PH.J=6;
var AH=function AH(a,b){return aH($CLJS.ui(AH,a),$CLJS.xd,a.g?a.g(b):a.call(null,b))},bH=function bH(a,b){return aH($CLJS.ui(bH,a),a,b)},BH=function BH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return BH.o(0<c.length?new $CLJS.lc(c.slice(0),0,null):null)};BH.o=function(a){return 1<$CLJS.D(a)?$CLJS.Ue(a):$CLJS.A(a)};BH.J=0;BH.I=function(a){return this.o($CLJS.y(a))};UH=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683);
WH=new $CLJS.M(null,"descending","descending",-24766135);$CLJS.pH=new $CLJS.M(null,"source","source",-433931539);XH=new $CLJS.M(null,"field-id","field-id",-353751335);qH=new $CLJS.M(null,"field_ref","field_ref",-1985640334);YH=new $CLJS.M(null,"datetime-field","datetime-field",21731696);ZH=new $CLJS.M(null,"joined-field","joined-field",-2048778268);$H=new $CLJS.M(null,"field-literal","field-literal",-1295883554);aI=new $CLJS.M(null,"viz-settings","viz-settings",256055379);
bI=new $CLJS.M(null,"form","form",-1624062471);cI=new $CLJS.M(null,"special-fn","special-fn",1290649344);dI=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941);kH=new $CLJS.M(null,"ignore-path","ignore-path",944069061);eI=new $CLJS.M(null,"named","named",-422393479);fI=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);oH=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);gI=new $CLJS.M(null,"path","path",-188191168);
hI=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738);iI=new $CLJS.M(null,"rows","rows",850049680);jI=new $CLJS.M(null,"as","as",1148689641);kI=new $CLJS.M(null,"ascending","ascending",-988350486);var lI,mI=$CLJS.vh.g($CLJS.ie),nI=$CLJS.vh.g($CLJS.ie),oI=$CLJS.vh.g($CLJS.ie),pI=$CLJS.vh.g($CLJS.ie),qI=$CLJS.K.j($CLJS.ie,$CLJS.XD,$CLJS.Rh.v?$CLJS.Rh.v():$CLJS.Rh.call(null));lI=new $CLJS.IC($CLJS.Fg.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Ji.h(YG,$CLJS.A),qI,mI,nI,oI,pI);$CLJS.JC(lI,$CLJS.xp,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xp,a instanceof $CLJS.M?$CLJS.bD(a):a],null)});
$CLJS.JC(lI,fI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?$CLJS.Gd.h(lI.g(new $CLJS.P(null,3,5,$CLJS.Q,[fI,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[fI,jH.h?jH.h(b,kH):jH.call(null,b,kH),YG(c)],null)});
$CLJS.JC(lI,$CLJS.px,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=jH.h?jH.h(a,kH):jH.call(null,a,kH);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,b,function(){var d=$CLJS.p($CLJS.xy.g(c))?$CLJS.wD.j(c,$CLJS.xy,$CLJS.yg):c;d=$CLJS.p($CLJS.Pw.g(c))?$CLJS.wD.j(d,$CLJS.Pw,$CLJS.yg):d;return $CLJS.p($CLJS.pr.g(c))?$CLJS.wD.j(d,$CLJS.pr,function(e){return $CLJS.p($CLJS.Kr.g(e))?$CLJS.wD.j(e,$CLJS.Kr,$CLJS.yg):e}):d}()],null)});
$CLJS.JC(lI,$H,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$H,b instanceof $CLJS.M?$CLJS.bD(b):b,$CLJS.yg.g(a)],null)});$CLJS.JC(lI,YH,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?new $CLJS.P(null,4,5,$CLJS.Q,[YH,jH.h?jH.h(b,kH):jH.call(null,b,kH),jI,YG(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[YH,jH.h?jH.h(b,kH):jH.call(null,b,kH),YG(c)],null)});
$CLJS.JC(lI,$CLJS.Jw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.p(a)?$CLJS.Gd.h(lI.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jw,b,c,d],null)),jH.h?jH.h(a,kH):jH.call(null,a,kH)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Jw,jH.h?jH.h(b,kH):jH.call(null,b,kH),$CLJS.dd(c)?c:YG(c),YG(d)],null)});
$CLJS.JC(lI,$CLJS.iv,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iv,b,YG(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iv,$CLJS.Cs],null)});$CLJS.JC(lI,$CLJS.sq,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sq,b,YG(a)],null)});
$CLJS.JC(lI,$CLJS.Ar,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ar,jH.h?jH.h(b,kH):jH.call(null,b,kH),c,YG(a)],null)});$CLJS.JC(lI,$CLJS.wv,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wv,jH.h?jH.h(b,kH):jH.call(null,b,kH),c,YG(a)],null)});
$CLJS.JC(lI,$CLJS.yv,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yv,jH.h?jH.h(b,kH):jH.call(null,b,kH),YG(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yv,jH.h?jH.h(b,kH):jH.call(null,b,kH)],null)});
$CLJS.JC(lI,$CLJS.Qw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Qw,jH.h?jH.h(b,kH):jH.call(null,b,kH),YG(c),YG(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qw,jH.h?jH.h(b,kH):jH.call(null,b,kH),YG(c)],null)});
$CLJS.JC(lI,$CLJS.Sw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sw,jH.h?jH.h(b,kH):jH.call(null,b,kH),jH.h?jH.h(c,kH):jH.call(null,c,kH),YG(a)],null)});$CLJS.JC(lI,$CLJS.Yn,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yn,b,a],null)});
$CLJS.JC(lI,$CLJS.bt,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.C(b);return $CLJS.uf.j(new $CLJS.P(null,1,5,$CLJS.Q,[YG(a)],null),$CLJS.xg.g(function(c){return jH.h?jH.h(c,kH):jH.call(null,c,kH)}),b)});
var rI=function rI(a){if($CLJS.p($CLJS.yh.h($CLJS.qm,$CLJS.km)(a))){var c=YG(a);var d=new $CLJS.R(null,new $CLJS.n(null,18,[$CLJS.py,null,$CLJS.jt,null,$CLJS.ry,null,$CLJS.kt,null,$CLJS.qw,null,$CLJS.Tw,null,$CLJS.$q,null,$CLJS.kw,null,$CLJS.gu,null,$CLJS.Es,null,$CLJS.jv,null,$CLJS.wu,null,$CLJS.Sp,null,$CLJS.xu,null,$CLJS.gx,null,$CLJS.lx,null,$CLJS.cu,null,$CLJS.ky,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.p(c)?c:$CLJS.p(hH(a))?(a=$CLJS.A(a),rI.g?rI.g(a):rI.call(null,
a)):null},tI=new $CLJS.n(null,8,[$CLJS.xn,YG,$CLJS.wx,function(a){a=ZG(a);return $CLJS.y($CLJS.jq.g(a))?$CLJS.wD.j(a,$CLJS.jq,nH):a},$CLJS.Ux,new $CLJS.n(null,6,[$CLJS.Ds,function sI(a){if($CLJS.p($CLJS.yh.h($CLJS.qm,$CLJS.km)(a)))return YG(a);if($CLJS.p(iH(eI,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.C(a);a=$CLJS.A(c);c=$CLJS.C(c);return $CLJS.uf.h(new $CLJS.P(null,2,5,$CLJS.Q,[eI,sI.g?sI.g(a):sI.call(null,a)],null),c)}return $CLJS.p($CLJS.p(hH(a))?rI($CLJS.Mc(a)):null)?$CLJS.Ah.h(sI,a):jH.h?jH.h(a,
kH):jH.call(null,a,kH)},$CLJS.Fu,function(a){return $CLJS.uf.h($CLJS.ie,function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);r=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bD(r),jH.h?jH.h(m,kH):jH.call(null,m,kH)],null);l.add(r);k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),
null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bD(l),jH.h?jH.h(f,kH):jH.call(null,f,kH)],null),d($CLJS.qc(e)))}return null}},null)}(a)}())},$CLJS.es,function(a){return $CLJS.Ue(function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k);m=$CLJS.p(hH(m))?lI.g(m):$CLJS.Hd(lI.g($CLJS.Hd(m)));l.add(m);
k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),null)}l=$CLJS.A(e);return $CLJS.Jd($CLJS.p(hH(l))?lI.g(l):$CLJS.Hd(lI.g($CLJS.Hd(l))),d($CLJS.qc(e)))}return null}},null)}(a)}())},$CLJS.Fv,function(a){a=ZG(a);a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.wx);if($CLJS.p(b))return a=$CLJS.Mo(a,new $CLJS.n(null,1,[$CLJS.wx,$CLJS.Ux],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wx],null),a=jH.h?jH.h(a,b):jH.call(null,a,b),$CLJS.Mo(a,new $CLJS.n(null,1,[$CLJS.Ux,$CLJS.wx],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ux],null);return jH.h?jH.h(a,b):jH.call(null,a,b)},$CLJS.Ks,new $CLJS.n(null,1,[UH,rH],null),$CLJS.gy,new $CLJS.n(null,1,[UH,function(a){a=jH.h?jH.h(a,$CLJS.Ux):jH.call(null,a,$CLJS.Ux);var b=$CLJS.je(a),c=$CLJS.K.h(b,$CLJS.Kr),d=$CLJS.K.h(b,$CLJS.wy);a=$CLJS.K.h(b,$CLJS.iw);b=$CLJS.p(c)?$CLJS.wD.j(b,$CLJS.Kr,YG):b;d=$CLJS.p($CLJS.yh.h($CLJS.qm,$CLJS.km)(d))?$CLJS.wD.j(b,$CLJS.wy,YG):b;return $CLJS.p(a)?$CLJS.wD.j(d,$CLJS.iw,$CLJS.bD):d}],null)],null),
$CLJS.Lh,new $CLJS.n(null,1,[$CLJS.tu,$CLJS.xd],null),$CLJS.dr,new $CLJS.n(null,1,[UH,function(a){var b=$CLJS.je(a),c=$CLJS.K.h(b,$CLJS.xn);a=$CLJS.K.h(b,$CLJS.lq);var d=$CLJS.K.h(b,$CLJS.My);b=$CLJS.p(d)?$CLJS.wD.j(b,$CLJS.My,$CLJS.bD):b;c=$CLJS.p(c)?$CLJS.wD.j(b,$CLJS.xn,YG):b;return $CLJS.p(a)?$CLJS.wD.j(c,$CLJS.lq,function(e){return jH.h?jH.h(e,kH):jH.call(null,e,kH)}):c}],null),$CLJS.Mx,function(a){return null==a?null:YG(a)},$CLJS.Ks,new $CLJS.n(null,1,[UH,rH],null),aI,YG],null),jH=function jH(a){for(var c=
[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return jH.o(arguments[0],1<c.length?new $CLJS.lc(c.slice(1),0,null):null)};
jH.o=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Ue(b);b=$CLJS.y(c)?$CLJS.qd($CLJS.K,tI,c):null;try{return $CLJS.mm(b)?b.g?b.g(a):b.call(null,a):$CLJS.Yc(a)?a:$CLJS.Xc(a)?$CLJS.uf.h($CLJS.ie,function(){return function g(f){return new $CLJS.Ld(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.ad(l)){var k=$CLJS.Rb(l),m=$CLJS.D(k),r=$CLJS.Od(m);a:for(var v=0;;)if(v<m){var w=$CLJS.Pc(k,v),x=$CLJS.I(w,0,null);w=$CLJS.I(w,1,null);x=YG(x);
x=new $CLJS.P(null,2,5,$CLJS.Q,[x,jH.o(w,$CLJS.G([$CLJS.Gd.h($CLJS.Ue(c),x)]))],null);r.add(x);v+=1}else{k=!0;break a}return k?$CLJS.Rd($CLJS.Td(r),g($CLJS.Sb(l))):$CLJS.Rd($CLJS.Td(r),null)}k=$CLJS.A(l);r=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);r=YG(r);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[r,jH.o(k,$CLJS.G([$CLJS.Gd.h($CLJS.Ue(c),r)]))],null),g($CLJS.qc(l)))}return null}},null)}(a)}()):$CLJS.p(hH(a))?lI.g(a):$CLJS.Wc(a)?$CLJS.Ah.h(function(e){return jH.o(e,$CLJS.G([$CLJS.Gd.h($CLJS.Ue(c),UH)]))},
a):a}catch(e){if(e instanceof Error){var d=e;throw $CLJS.Go($CLJS.$C("Error normalizing form: {0}",$CLJS.G([SG(d)])),new $CLJS.n(null,3,[bI,a,gI,c,cI,b],null),d);}throw e;}};jH.J=1;jH.I=function(a){var b=$CLJS.A(a);a=$CLJS.C(a);return this.o(b,a)};
var tH=function(){var a=$CLJS.vh.g($CLJS.ie),b=$CLJS.vh.g($CLJS.ie),c=$CLJS.vh.g($CLJS.ie),d=$CLJS.vh.g($CLJS.ie),e=$CLJS.K.j($CLJS.ie,$CLJS.XD,$CLJS.Rh.v?$CLJS.Rh.v():$CLJS.Rh.call(null));return new $CLJS.IC($CLJS.Fg.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.p(hH(f))?$CLJS.A(f):null},e,a,b,c,d)}();$CLJS.JC(tH,$CLJS.bt,function(a){return a});
$CLJS.JC(tH,$CLJS.px,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.p(iH($CLJS.px,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return tH.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,c,$CLJS.ke($CLJS.Ko($CLJS.G([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,b,$CLJS.ke(a)],null)});$CLJS.JC(tH,XH,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.p(hH(a))?tH.g(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,a,null],null)});
$CLJS.JC(tH,$H,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,b,new $CLJS.n(null,1,[$CLJS.xy,a],null)],null)});$CLJS.JC(tH,hI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=uH(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=uH(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,a,$CLJS.Cg.j(c,$CLJS.Vu,b)],null)});
$CLJS.JC(tH,ZH,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=uH(a);return $CLJS.ce($CLJS.BD,a,$CLJS.Cg,$CLJS.G([$CLJS.Os,b]))});
$CLJS.JC(tH,YH,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=uH(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.je(c);c=$CLJS.K.h(c,$CLJS.xy);return $CLJS.La(c)||$CLJS.yz.h(c,b)?$CLJS.ce($CLJS.BD,a,$CLJS.Cg,$CLJS.G([$CLJS.Pw,b])):a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),tH.g(new $CLJS.P(null,3,5,$CLJS.Q,[YH,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.q.g(b)].join(""));}});$CLJS.JC(tH,fI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=uH(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.px,b,$CLJS.Cg.j(e,$CLJS.pr,$CLJS.Ko($CLJS.G([new $CLJS.n(null,1,[$CLJS.Kr,c],null),$CLJS.p(d)?$CLJS.he([c,d]):null,a])))],null)});
for(var uI=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qs,$CLJS.xq,$CLJS.bu],null)),vI=null,wI=0,xI=0;;)if(xI<wI){var yI=vI.ja(null,xI);$CLJS.JC(tH,yI,function(){return function(a){return vH(a)}}(uI,vI,wI,xI,yI));xI+=1}else{var zI=$CLJS.y(uI);if(zI){var AI=zI;if($CLJS.ad(AI)){var BI=$CLJS.Rb(AI),CI=$CLJS.Sb(AI),DI=BI,EI=$CLJS.D(BI);uI=CI;vI=DI;wI=EI}else{var FI=$CLJS.A(AI);$CLJS.JC(tH,FI,function(){return function(a){return vH(a)}}(uI,vI,wI,xI,FI,AI,zI));uI=$CLJS.C(AI);vI=null;wI=0}xI=0}else break}
$CLJS.JC(tH,$CLJS.fy,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.C(a);a=$CLJS.A(b);var c=$CLJS.C(b);b=$CLJS.A(c);c=$CLJS.C(c);return $CLJS.uf.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fy,uH(a),uH(b)],null),c)});$CLJS.JC(tH,$CLJS.Jw,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.C(a);a=$CLJS.A(b);b=$CLJS.C(b);var c=uH(a);a=$CLJS.p($CLJS.cD($CLJS.px,a))?$CLJS.BD.o(c,$CLJS.sh,$CLJS.G([$CLJS.Pw])):c;return $CLJS.uf.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jw,a],null),b)});
for(var GI=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.iu,$CLJS.Au,$CLJS.Xw,$CLJS.Tu,$CLJS.Su,$CLJS.Ct,$CLJS.zr,$CLJS.wq,$CLJS.Nu,$CLJS.pp,$CLJS.nu,$CLJS.rv,$CLJS.Hv,$CLJS.lt,$CLJS.Pq],null)),HI=null,II=0,JI=0;;)if(JI<II){var KI=HI.ja(null,JI);$CLJS.JC(tH,KI,function(){return function(a){return wH(a)}}(GI,HI,II,JI,KI));JI+=1}else{var LI=$CLJS.y(GI);if(LI){var MI=LI;if($CLJS.ad(MI)){var NI=$CLJS.Rb(MI),OI=$CLJS.Sb(MI),PI=NI,QI=$CLJS.D(NI);GI=OI;HI=PI;II=QI}else{var RI=$CLJS.A(MI);$CLJS.JC(tH,RI,function(){return function(a){return wH(a)}}(GI,
HI,II,JI,RI,MI,LI));GI=$CLJS.C(MI);HI=null;II=0}JI=0}else break}$CLJS.JC(tH,iI,function(){return null});$CLJS.JC(tH,$CLJS.xw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xw,tH.g(b),a],null)});
$CLJS.JC(tH,eI,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.C(a);var b=$CLJS.A(a),c=$CLJS.C(a);a=$CLJS.A(c);var d=$CLJS.C(c);c=tH.g;var e=$CLJS.Q;b=tH.g(b);d=$CLJS.I(d,0,null);d=$CLJS.je(d);a=!1===$CLJS.K.h(d,dI)?new $CLJS.n(null,1,[$CLJS.T,a],null):new $CLJS.n(null,1,[$CLJS.lw,a],null);return c.call(tH,new $CLJS.P(null,3,5,e,[$CLJS.xw,b,a],null))});
for(var SI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lx,$CLJS.Tw],null)),TI=null,UI=0,VI=0;;)if(VI<UI){var WI=TI.ja(null,VI);$CLJS.JC(tH,WI,function(){return function(a){return xH(a)}}(SI,TI,UI,VI,WI));VI+=1}else{var XI=$CLJS.y(SI);if(XI){var YI=XI;if($CLJS.ad(YI)){var ZI=$CLJS.Rb(YI),$I=$CLJS.Sb(YI),aJ=ZI,bJ=$CLJS.D(ZI);SI=$I;TI=aJ;UI=bJ}else{var cJ=$CLJS.A(YI);$CLJS.JC(tH,cJ,function(){return function(a){return xH(a)}}(SI,TI,UI,VI,cJ,YI,XI));SI=$CLJS.C(YI);TI=null;UI=0}VI=0}else break}
for(var dJ=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.cu,$CLJS.Gx,$CLJS.gu,$CLJS.ry,$CLJS.ky,$CLJS.py,$CLJS.gx,$CLJS.Sp,$CLJS.wu],null)),eJ=null,fJ=0,gJ=0;;)if(gJ<fJ){var hJ=eJ.ja(null,gJ);$CLJS.JC(tH,hJ,function(){return function(a){return yH(a)}}(dJ,eJ,fJ,gJ,hJ));gJ+=1}else{var iJ=$CLJS.y(dJ);if(iJ){var jJ=iJ;if($CLJS.ad(jJ)){var kJ=$CLJS.Rb(jJ),lJ=$CLJS.Sb(jJ),mJ=kJ,nJ=$CLJS.D(kJ);dJ=lJ;eJ=mJ;fJ=nJ}else{var oJ=$CLJS.A(jJ);$CLJS.JC(tH,oJ,function(){return function(a){return yH(a)}}(dJ,eJ,fJ,gJ,
oJ,jJ,iJ));dJ=$CLJS.C(jJ);eJ=null;fJ=0}gJ=0}else break}$CLJS.JC(tH,$CLJS.Es,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,uH(b),a],null)});
for(var pJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xu,$CLJS.kt],null)),qJ=null,rJ=0,sJ=0;;)if(sJ<rJ){var tJ=qJ.ja(null,sJ);$CLJS.JC(tH,tJ,function(){return function(a){return zH(a)}}(pJ,qJ,rJ,sJ,tJ));sJ+=1}else{var uJ=$CLJS.y(pJ);if(uJ){var vJ=uJ;if($CLJS.ad(vJ)){var wJ=$CLJS.Rb(vJ),xJ=$CLJS.Sb(vJ),yJ=wJ,zJ=$CLJS.D(wJ);pJ=xJ;qJ=yJ;rJ=zJ}else{var AJ=$CLJS.A(vJ);$CLJS.JC(tH,AJ,function(){return function(a){return zH(a)}}(pJ,qJ,rJ,sJ,AJ,vJ,uJ));pJ=$CLJS.C(vJ);qJ=null;rJ=0}sJ=0}else break}
$CLJS.JC(tH,$CLJS.$q,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$q,tH.g(b),tH.g(a)],null)});
$CLJS.JC(tH,$CLJS.Bw,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?$CLJS.Gd.h(tH.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bw,b],null)),jH.o(a,$CLJS.G([kH]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bw,$CLJS.Ue(function(){return function e(d){return new $CLJS.Ld(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.ad(f)){var g=$CLJS.Rb(f),l=$CLJS.D(g),k=$CLJS.Od(l);a:for(var m=0;;)if(m<l){var r=$CLJS.Pc(g,m),v=$CLJS.I(r,0,null);r=$CLJS.I(r,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[tH.g(v),tH.g(r)],null);k.add(v);m+=1}else{g=!0;break a}return g?$CLJS.Rd($CLJS.Td(k),e($CLJS.Sb(f))):$CLJS.Rd($CLJS.Td(k),null)}g=$CLJS.A(f);k=$CLJS.I(g,0,null);g=$CLJS.I(g,1,null);return $CLJS.Jd(new $CLJS.P(null,2,5,$CLJS.Q,[tH.g(k),tH.g(g)],null),e($CLJS.qc(f)))}return null}},null)}(b)}())],null)});
var CH=function CH(a){return function f(d,e){try{if($CLJS.p(function(){var w=$CLJS.XC($CLJS.Hh);return w.g?w.g(e):w.call(null,e)}()))return f(d,$CLJS.Ue(e));throw $CLJS.Z;}catch(w){if(w instanceof Error)if(d=w,d===$CLJS.Z)try{if($CLJS.p(function(){var x=$CLJS.XC($CLJS.qm);return x.g?x.g(e):x.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.Zc(e)&&2<=$CLJS.D(e))try{var g=
$CLJS.MC(e,0,2);if($CLJS.Zc(g)&&2===$CLJS.D(g))try{var l=$CLJS.F(g,0);if($CLJS.p($CLJS.xh.h($CLJS.qm,$CLJS.ue(new $CLJS.R(null,new $CLJS.n(null,5,[$CLJS.jt,null,$CLJS.qw,null,$CLJS.kw,null,$CLJS.jv,null,eI,null],null),null)))(l)))try{var k=$CLJS.F(g,1);if($CLJS.p(rI(k)))return $CLJS.Ue($CLJS.ud($CLJS.th,$CLJS.xg.h(CH,a)));throw $CLJS.Z;}catch(z){if(z instanceof Error){var m=z;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error){m=z;if(m===$CLJS.Z)throw $CLJS.Z;
throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error){m=z;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error)if(m=z,m===$CLJS.Z)try{if($CLJS.Zc(e)&&1<=$CLJS.D(e))try{var r=$CLJS.MC(e,0,1);if($CLJS.Zc(r)&&1===$CLJS.D(r))try{if($CLJS.F(r,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw m;else throw z;}else throw d;else throw x;}else throw d;else throw w;}}($CLJS.Te,a)},BJ=function BJ(a){return function f(d,e){try{var g=$CLJS.XC($CLJS.Hh);var l=g.g?g.g(e):g.call(null,e);if($CLJS.p(l))return f(d,$CLJS.Ue(e));throw $CLJS.Z;}catch(z){if(z instanceof Error)if(l=z,l===$CLJS.Z)try{if($CLJS.Zc(e)&&2===$CLJS.D(e))try{var k=
$CLJS.F(e,1);if($CLJS.O(k,$CLJS.Bt)){var m=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,m],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{k=$CLJS.F(e,1);if($CLJS.O(k,$CLJS.Ps))return m=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,m],null));throw $CLJS.Z;}catch(N){if(N instanceof Error){var r=N;if(r===$CLJS.Z)try{k=$CLJS.F(e,1);if($CLJS.O(k,kI))return m=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,m],null));throw $CLJS.Z;}catch(Y){if(Y instanceof
Error)if(l=Y,l===$CLJS.Z)try{k=$CLJS.F(e,1);if($CLJS.O(k,WH))return m=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,m],null));throw $CLJS.Z;}catch(la){if(la instanceof Error)if(k=la,k===$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.O(v,kI))return m=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,m],null));throw $CLJS.Z;}catch(va){if(va instanceof Error)if(k=va,k===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.O(v,WH))return m=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,m],null));throw $CLJS.Z;
}catch(Ja){if(Ja instanceof Error)if(k=Ja,k===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.O(v,$CLJS.Bt))return m=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,uH(m)],null);throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error)if(k=Ea,k===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.O(v,$CLJS.Ps))return m=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ps,uH(m)],null);throw $CLJS.Z;}catch(cb){if(cb instanceof Error){m=cb;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw cb;}else throw k;else throw Ea;}else throw k;else throw Ja;
}else throw k;else throw va;}else throw k;else throw la;}else throw l;else throw Y;}else throw r;}else throw N;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{if($CLJS.Zc(e)&&0<=$CLJS.D(e))try{var w=$CLJS.MC(e,0,0);if($CLJS.Zc(w)&&0===$CLJS.D(w))try{var x=$CLJS.NC(e,0);if($CLJS.E.h(x,a))return $CLJS.Ue($CLJS.Ii.g($CLJS.xg.h(BJ,a)));throw $CLJS.Z;}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)throw $CLJS.Z;throw r;}throw N;}else throw $CLJS.Z;
}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)throw $CLJS.Z;throw r;}throw N;}else throw $CLJS.Z;}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)return $CLJS.ZC(f,d,e);throw r;}throw N;}else throw l;else throw H;}else throw l;else throw z;}}($CLJS.Te,a)},KH=$CLJS.Ji.h(WG,function(a){var b=MH($CLJS.Ds.g(a))?$CLJS.wD.j(a,$CLJS.Ds,DH):a;b=MH($CLJS.cw.g(a))?$CLJS.wD.j(b,$CLJS.cw,EH):b;b=MH($CLJS.wy.g(a))?$CLJS.wD.j(b,$CLJS.wy,$CLJS.ui($CLJS.Ah,sH)):b;b=MH($CLJS.es.g(a))?$CLJS.wD.j(b,$CLJS.es,
BJ):b;return MH($CLJS.Fv.g(a))?$CLJS.wD.j(b,$CLJS.Fv,LH):b}),CJ=new $CLJS.n(null,3,[$CLJS.wx,$CLJS.xd,$CLJS.Ux,new $CLJS.n(null,2,[$CLJS.Fv,function(a){a=$CLJS.je(a);var b=$CLJS.K.h(a,$CLJS.wx);if($CLJS.p(b))return a=$CLJS.Mo(a,new $CLJS.n(null,1,[$CLJS.wx,$CLJS.Ux],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wx],null),a=SH.h?SH.h(a,b):SH.call(null,a,b),$CLJS.Mo(a,new $CLJS.n(null,1,[$CLJS.Ux,$CLJS.wx],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ux],null);return SH.h?SH.h(a,b):SH.call(null,a,b)},
$CLJS.gy,new $CLJS.n(null,1,[UH,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ux],null);return SH.h?SH.h(a,b):SH.call(null,a,b)}],null)],null),aI,$CLJS.xd],null),SH=function SH(a){switch(arguments.length){case 1:return SH.g(arguments[0]);case 2:return SH.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};SH.g=function(a){return SH.h(a,$CLJS.Te)};
SH.h=function(a,b){try{var c=$CLJS.y(b)?$CLJS.qd($CLJS.K,CJ,b):null;return $CLJS.mm(c)?c.g?c.g(a):c.call(null,a):$CLJS.Yc(a)?a:$CLJS.Xc(a)?TH(a,b):$CLJS.Wc(a)?VH(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Go("Error removing empty clauses from form.",new $CLJS.n(null,2,[bI,a,gI,b],null),d);throw d;}};SH.J=2;
$CLJS.DJ=function(){var a=$CLJS.Ji.o(SH,RH,OH,$CLJS.G([jH]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Go($CLJS.$C("Error normalizing query: {0}",$CLJS.G([SG(c)])),new $CLJS.n(null,1,[$CLJS.Ux,b],null),c);}throw d;}}}();