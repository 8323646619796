var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./schema.utils.js");require("./schema.spec.core.js");
'use strict';var Yl,fm,Zl,gm,hm,cm,Xl;
Yl=function(a,b,c){if($CLJS.Zc(a)){var d=$CLJS.A(a);d=d instanceof $CLJS.M?d.T:null;switch(d){case "schema.spec.collection/optional":return a=$CLJS.C(a),$CLJS.Wl.j?$CLJS.Wl.j(a,b,c):$CLJS.Wl.call(null,a,b,c);case "schema.spec.collection/remaining":if(!$CLJS.E.h(2,$CLJS.D(a)))throw Error($CLJS.jl("remaining can have only one schema."));var e=$CLJS.Gl($CLJS.Mc(a),b);return function(g,l){$CLJS.wh.j(g,$CLJS.uf,$CLJS.xg.h(e,l));return c.h?c.h(g,null):c.call(null,g,null)};default:throw Error(["No matching clause: ",
$CLJS.q.g(d)].join(""));}}else{var f=Xl.g(a);e=$CLJS.Gl(a,b);return function(g,l){var k=function(){function m(r){return $CLJS.wh.j(g,$CLJS.Gd,$CLJS.wl(r)?r:e.g?e.g(r):e.call(null,r))}return f.h?f.h(m,l):f.call(null,m,l)}();return c.h?c.h(g,k):c.call(null,g,k)}}};$CLJS.Wl=function(a,b,c){if(!$CLJS.te(function(d){return $CLJS.Zc(d)&&$CLJS.E.h($CLJS.A(d),Zl)},$CLJS.jg(a)))throw Error($CLJS.jl("Remaining schemas must be in tail position."));return $CLJS.qd(function(d,e){return Yl(e,b,d)},c,$CLJS.Hd(a))};
$CLJS.$l=function(a,b,c,d,e,f,g){this.Y=a;this.ub=b;this.elements=c;this.Wa=d;this.C=e;this.l=f;this.B=g;this.A=2230716170;this.M=139264};$CLJS.am=function(a,b,c,d){return new $CLJS.$l(a,b,c,d,null,null,null)};$CLJS.bm=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[Zl,new $CLJS.n(null,2,[$CLJS.Cl,a,Xl,function(){throw Error("should never be not called");}],null)],null)};$CLJS.dm=function(a,b,c){b=new $CLJS.n(null,2,[$CLJS.Cl,b,Xl,c],null);return $CLJS.p(a)?b:$CLJS.Ue($CLJS.Jd(cm,$CLJS.G([b])))};
$CLJS.em=function(a,b,c){return $CLJS.uf.h($CLJS.Ue($CLJS.Jd(cm,$CLJS.G([new $CLJS.n(null,2,[$CLJS.Cl,a,Xl,b],null)]))),c)};fm=new $CLJS.M(null,"elements","elements",657646735);Zl=new $CLJS.M("schema.spec.collection","remaining","schema.spec.collection/remaining",-421177821);gm=new $CLJS.M(null,"constructor","constructor",-1953928811);hm=new $CLJS.M(null,"on-error","on-error",1728533530);cm=new $CLJS.M("schema.spec.collection","optional","schema.spec.collection/optional",-854614927);
Xl=new $CLJS.M(null,"parser","parser",-1543495310);$CLJS.h=$CLJS.$l.prototype;$CLJS.h.ga=function(a,b){return this.N(null,b,null)};$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "pre":return this.Y;case "constructor":return this.ub;case "elements":return this.elements;case "on-error":return this.Wa;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.Lb=$CLJS.qa(17);
$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#schema.spec.collection.CollectionSpec{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Il,this.Y],null),new $CLJS.P(null,2,5,$CLJS.Q,[gm,this.ub],null),new $CLJS.P(null,2,5,$CLJS.Q,[fm,this.elements],null),new $CLJS.P(null,2,5,$CLJS.Q,[hm,this.Wa],null)],null),this.l))};
$CLJS.h.ia=function(){return new $CLJS.kf(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Il,gm,fm,hm],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};$CLJS.h.ba=function(){return 4+$CLJS.D(this.l)};$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=1800698765^$CLJS.vc(this)};
$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.Y,b.Y)&&$CLJS.E.h(this.constructor,b.constructor)&&$CLJS.E.h(this.elements,b.elements)&&$CLJS.E.h(this.Wa,b.Wa)&&$CLJS.E.h(this.l,b.l)};
$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,4,[$CLJS.Il,null,fm,null,gm,null,hm,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new $CLJS.$l(this.Y,this.ub,this.elements,this.Wa,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "pre":case "constructor":case "elements":case "on-error":return!0;default:return $CLJS.fd(this.l,b)}};
$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Il,b):$CLJS.O.call(null,$CLJS.Il,b))?new $CLJS.$l(c,this.ub,this.elements,this.Wa,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(gm,b):$CLJS.O.call(null,gm,b))?new $CLJS.$l(this.Y,c,this.elements,this.Wa,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(fm,b):$CLJS.O.call(null,fm,b))?new $CLJS.$l(this.Y,this.ub,c,this.Wa,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(hm,b):$CLJS.O.call(null,hm,b))?new $CLJS.$l(this.Y,this.ub,this.elements,
c,this.C,this.l,null):new $CLJS.$l(this.Y,this.ub,this.elements,this.Wa,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.gd($CLJS.Il,this.Y),new $CLJS.gd(gm,this.ub),new $CLJS.gd(fm,this.elements),new $CLJS.gd(hm,this.Wa)],null),this.l))};$CLJS.h.aa=function(a,b){return new $CLJS.$l(this.Y,this.ub,this.elements,this.Wa,b,this.l,this.B)};
$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};