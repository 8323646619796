import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { color } from "metabase/lib/colors";
import { monospaceFontFamily, space } from "metabase/styled-components/theme";
import IconButtonWrapper from "metabase/components/IconButtonWrapper";
import { InputSize } from "./types";

export interface InputProps {
  fieldSize?: InputSize;
  hasError?: boolean;
  fullWidth?: boolean;
  hasSubtitle?: boolean;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  isDark?: boolean;
  isStudio?: boolean;
}

export const InputRoot = styled.div<InputProps>`
  display: inline-flex;
  align-items: center;
  position: relative;
  width: ${props => (props.fullWidth ? "100%" : "")};
`;

export const InputField = styled.input<InputProps>`
  font-family: inherit;
  font-weight: 700;
  font-size: 1rem;
  color: ${props => props.isDark ? color("") : color("text-dark")};
  padding: 0.75rem;
  border: 1px solid ${color("border")};
  border-radius: ${space(1)};

  border: 1px solid ${props => props.isDark ? color("--color-border-dark") : color("border")};
  background-color: ${props => {
    if (props.isStudio) {
      return "#182034";
    }
    if (props.isDark) {
      return color(props.readOnly ? "--color-bg-black" : "--color-bg-dark")
    }
    return color(props.readOnly ? "bg-light" : "bg-white")
  }};
  outline: none;
  text-align: inherit;

  &:focus,
  &:hover {
    border-color: ${props => props.isDark ? color("--color-primary-dark") : color("brand")};
    transition: border 300ms ease-in-out;
  }

  ${props =>
    props.hasError &&
    css`
      border-color: ${color("error")};
    `};

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${props =>
    props.hasLeftIcon &&
    css`
      padding-left: 2.25rem;
    `};

  ${props =>
    props.hasRightIcon &&
    css`
      padding-right: 2.25rem;
    `};

  ${props =>
    props.fieldSize === "small" &&
    css`
      font-size: 0.875rem;
      line-height: 1rem;
      padding: 0.4375rem 0.625rem;
    `};

  ${props =>
    props.hasSubtitle &&
    css`
      padding-top: 1.75rem;
    `};
`;

export const InputButton = styled(IconButtonWrapper)`
  position: absolute;
  color: ${color("text-light")};
  padding: 0.75rem;
  border-radius: 50%;
`;

export const InputLeftButton = styled(InputButton)`
  left: 0;
`;

export const InputRightButton = styled(InputButton)`
  right: 0;
`;

export const InputSubtitle = styled.div`
  color: ${color("text-light")};
  position: absolute;
  top: 1.25em;
  left: 1.25em;
  font-family: ${monospaceFontFamily};
  font-size: 0.75em;
`;
