var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./schema.utils.js");require("./schema.spec.core.js");
'use strict';var Tl,Ml,Vl;
$CLJS.Ol=function(a,b,c){var d=$CLJS.Ll.g(a),e=$CLJS.Gl(a,b),f=$CLJS.p(d)?function(l){try{var k=d.g?d.g(l):d.call(null,l)}catch(m){if(m instanceof Object)k=Ml;else throw m;}return $CLJS.E.h(Ml,k)?$CLJS.vl($CLJS.ql($CLJS.Cl.g(a),l,new $CLJS.Jg(function(){return new $CLJS.Dd(null,$CLJS.Fg.g($CLJS.ml(d)),new $CLJS.Dd(null,$CLJS.ll(l),null,1,null),2,null)}),$CLJS.yl)):$CLJS.p(k)?e.g?e.g(l):e.call(null,l):c.g?c.g(l):c.call(null,l)}:e,g=$CLJS.Nl.g(a);return $CLJS.p(g)?function(l){l=f.g?f.g(l):f.call(null,
l);var k=$CLJS.xl(l);return $CLJS.p(k)?$CLJS.vl(g.g?g.g(k):g.call(null,k)):l}:f};$CLJS.Pl=function(a,b,c,d,e,f,g){this.Y=a;this.options=b;this.Va=c;this.Ua=d;this.C=e;this.l=f;this.B=g;this.A=2230716170;this.M=139264};$CLJS.Rl=function(a,b){return $CLJS.Ql(a,b,null)};$CLJS.Ql=function(a,b,c){return $CLJS.Sl(a,b,c,null)};
$CLJS.Sl=function(a,b,c,d){if(!$CLJS.p($CLJS.p(c)?c:null==$CLJS.Ll.g($CLJS.Nc(b))))throw Error($CLJS.jl("when last option has a guard, err-f must be provided"));return new $CLJS.Pl(a,b,c,d,null,null,null)};Tl=new $CLJS.M(null,"err-f","err-f",651620941);$CLJS.Ll=new $CLJS.M(null,"guard","guard",-873147811);Ml=new $CLJS.M("schema.spec.variant","exception","schema.spec.variant/exception",-253680523);$CLJS.Ul=new $CLJS.M(null,"options","options",99638489);Vl=new $CLJS.M(null,"post","post",269697687);
$CLJS.Nl=new $CLJS.M(null,"wrap-error","wrap-error",536732809);$CLJS.h=$CLJS.Pl.prototype;$CLJS.h.ga=function(a,b){return this.N(null,b,null)};$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "pre":return this.Y;case "options":return this.options;case "err-f":return this.Va;case "post":return this.Ua;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.Lb=$CLJS.qa(18);
$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#schema.spec.variant.VariantSpec{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Il,this.Y],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ul,this.options],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tl,this.Va],null),new $CLJS.P(null,2,5,$CLJS.Q,[Vl,this.Ua],null)],null),this.l))};
$CLJS.h.ia=function(){return new $CLJS.kf(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Il,$CLJS.Ul,Tl,Vl],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};$CLJS.h.ba=function(){return 4+$CLJS.D(this.l)};$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=-1524878868^$CLJS.vc(this)};
$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.Y,b.Y)&&$CLJS.E.h(this.options,b.options)&&$CLJS.E.h(this.Va,b.Va)&&$CLJS.E.h(this.Ua,b.Ua)&&$CLJS.E.h(this.l,b.l)};$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,4,[$CLJS.Il,null,Tl,null,Vl,null,$CLJS.Ul,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new $CLJS.Pl(this.Y,this.options,this.Va,this.Ua,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};
$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "pre":case "options":case "err-f":case "post":return!0;default:return $CLJS.fd(this.l,b)}};
$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Il,b):$CLJS.O.call(null,$CLJS.Il,b))?new $CLJS.Pl(c,this.options,this.Va,this.Ua,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Ul,b):$CLJS.O.call(null,$CLJS.Ul,b))?new $CLJS.Pl(this.Y,c,this.Va,this.Ua,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Tl,b):$CLJS.O.call(null,Tl,b))?new $CLJS.Pl(this.Y,this.options,c,this.Ua,this.C,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Vl,b):$CLJS.O.call(null,Vl,b))?new $CLJS.Pl(this.Y,this.options,
this.Va,c,this.C,this.l,null):new $CLJS.Pl(this.Y,this.options,this.Va,this.Ua,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.gd($CLJS.Il,this.Y),new $CLJS.gd($CLJS.Ul,this.options),new $CLJS.gd(Tl,this.Va),new $CLJS.gd(Vl,this.Ua)],null),this.l))};$CLJS.h.aa=function(a,b){return new $CLJS.Pl(this.Y,this.options,this.Va,this.Ua,b,this.l,this.B)};
$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};