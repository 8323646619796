export const getProject = location => {
  const targetLocation = location || window?.location;
  return targetLocation?.pathname?.startsWith("/defi360")
    ? "defi360"
    : "footprint";
};
export const isDefi360 = project => {
  return false;
  // return (project ? project : getProject()) === "defi360";
};

export const getLatestGAProjectId = () => {
  // return null;
  const userId = window.localStorage.getItem("GAUserId");
  const userRole = window.localStorage.getItem("GAUserRole");
  const projectIdStr = window.localStorage.getItem("LatestGAProjectId");
  // const isFgaDemoProject = window.localStorage.getItem("IsFgaDemoProject");
  // if (userId === null || isFgaDemoProject === "true") {
  //   return null;
  // }
  // if (!window.location.pathname.startsWith("/ab")) {
  //   return null;
  // }
  // if (userId !== "24733") {
  //   return null;
  // }
  if (userRole !== "project") {
    return null;
  }
  // const projectIdStr = window.localStorage.getItem("LatestGAProjectId");
  let projectId;
  try {
    projectId = projectIdStr && parseInt(projectIdStr);
  } catch (e) {}
  return projectId;
};

export const getLatestGAProjectName = () => {
  if (!window.location.pathname.startsWith("/ab/project")) {
    return null;
  }
  if (isWeb2Path()) {
    return window.localStorage.getItem("LatestWeb2SubProject") || "";
  }
  return window.localStorage.getItem("LatestGAProject") || "";
};

export const isWeb2Path = () => {
  const path = window.location.pathname;
  const web2PathArray = ["/monetization", "/user-acquisition", "/user-engagement", "/user-retention"];
  return web2PathArray.some(item => {
    return path.includes(item);
  })
};
