/* eslint-disable react/prop-types */
import React from "react";
import upperFirst from "lodash/upperFirst";
import upperCase from "lodash/upperCase";
import lowerCase from "lodash/lowerCase";
import keys from "lodash/keys";
import { DEFAULT_DATE_STYLE, DEFAULT_TIME_STYLE, getTimeFormatFromStyle, hasHour } from "metabase/lib/formatting/date";


export function formatTitle(title) {
  if (!title) {
    return title;
  }
  const lowerArray = [
    "a",
    "all",
    "the",
    "at",
    "with",
    "of",
    "for",
    "info",
    "in",
    "or",
    "and",
    "to",
    "about",
    "but",
    "by",
    "on",
    "vs",
  ];
  const upperArray = [
    "nft",
    "ens",
    "bnb",
  ];
  const mappingObject = {
    "defi": "DeFi",
  }
  const mapFunction = (item) => {
    if (!item) {
      return item;
    }
    if (lowerArray.includes(item.toLowerCase())) {
      return lowerCase(item);
    }
    if (upperArray.includes(item.toLowerCase())) {
      return upperCase(item);
    }
    if (keys(mappingObject).includes(item.toLowerCase())) {
      return mappingObject[item.toLowerCase()];
    }
    return upperFirst(item);
  }
  const titleBlock = title.split(" ");
  if (titleBlock && titleBlock.length > 0) {
    const result = titleBlock
      .map(item => mapFunction(item))
      .join(" ")
      .trim();
    return upperFirst(result);
  }

  return title.trim();
}

export function formatTableTitle(title) {
  if (!title) {
    return title;
  }
  return formatTitle(title.replaceAll("_", " "));
}

export function formatSectionTitle(title) {
  if (!title) {
    return title;
  }
  return formatTitle(title.replaceAll("-", " "));
}

export function formatDashboardChartSaveTitle(name) {
  if (!name) {
    return name;
  }
  return name.replaceAll(/\s+/g, " ").trim();
}

export function formatArticleSaveTitle(name) {
  if (!name) {
    return name;
  }
  return name.replaceAll(/\s+/g, " ").trim();
}

export function formatArticleTitle(title) {
  return formatTitle(title);
}

export function articleTitle(item) {
  if (!item) {
    return item;
  }
  return item.type === "article" ? formatArticleTitle(item.title) : item.title;
}

export function getDescription({ description, orderedCards }) {
  // 读取 description
  if (description) {
    return description;
  }

  if (orderedCards) {
    // 读取 text box
    const textCard = orderedCards
      ?.sort((a, b) => a.row - b.row)
      ?.find(item => item.card.display === "text");
    if (textCard) {
      return textCard.visualization_settings?.text
        ?.replace(/#/g, "") // markdown 标题处理
        ?.replace(/\s+/g, " ") // 空格处理
        ?.trim()
        ?.slice(0, 500); //限制最多 500 长度的desc
    } else {
      // 读取 chart title
      return orderedCards?.find(item => item.card.name)?.card?.name;
    }
  }

  return "";
}

export function getTableNameListFromSQL(nativeQuery) {
  return (
    nativeQuery?.match(/(?:FROM|from|join|JOIN)+(\s|`|")+(\w|`|"|\.)+/gi)?.map(item =>
      item
        .toLowerCase()
        .replace(/from|FROM/g, "")
        .replace(/join|JOIN/g, "")
        .replace(/`/g, "")
        .replace(/"/g, "")
        .trim()
        .replace(/iceberg.footprint./g, "")
        .replace(/footprint./g, ""),
    ) || []
  );
}
