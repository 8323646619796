var window=global;var $CLJS=require("./cljs_env");require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./schema.core.js");
'use strict';var po,ro;po=function(a){if(null!=a&&null!=a.Kb)a=a.Kb(a);else{var b=po[$CLJS.sa(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=po._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Oa("HasPrecondition.precondition",a);}return a};$CLJS.qo=function(a){return 0>a};ro=function(a,b,c,d){this.vb=a;this.C=b;this.l=c;this.B=d;this.A=2230716170;this.M=139264};$CLJS.so=function(a){return new ro(a,null,null,null)};$CLJS.Jl.prototype.Kb=$CLJS.ra(22,function(){return $CLJS.ue(this.Y)});
$CLJS.Pl.prototype.Kb=$CLJS.ra(21,function(){var a=this;return $CLJS.xh.h($CLJS.ue(a.Y),$CLJS.ae($CLJS.yh,function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k),r=$CLJS.je(m);m=$CLJS.K.h(r,$CLJS.Ll);r=$CLJS.K.h(r,$CLJS.Cl);m=$CLJS.p(m)?$CLJS.xh.h(m,po($CLJS.sm(r))):po($CLJS.sm(r));l.add(m);k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):
$CLJS.Rd($CLJS.Td(l),null)}l=$CLJS.A(e);f=$CLJS.je(l);l=$CLJS.K.h(f,$CLJS.Ll);f=$CLJS.K.h(f,$CLJS.Cl);return $CLJS.Jd($CLJS.p(l)?$CLJS.xh.h(l,po($CLJS.sm(f))):po($CLJS.sm(f)),d($CLJS.qc(e)))}return null}},null)}(a.options)}()))});$CLJS.$l.prototype.Kb=$CLJS.ra(20,function(){return $CLJS.ue(this.Y)});$CLJS.h=ro.prototype;$CLJS.h.ga=function(a,b){return this.N(null,b,null)};
$CLJS.h.N=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "schemas":return this.vb;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.oa=function(a,b,c){return $CLJS.qd(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.S=function(a,b,c){return $CLJS.rg(b,function(d){return $CLJS.rg(b,$CLJS.wg,""," ","",c,d)},"#schema.core.CondPre{",", ","}",c,$CLJS.th.h(new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hn,this.vb],null)],null),this.l))};$CLJS.h.ia=function(){return new $CLJS.kf(this,1,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Hn],null),$CLJS.p(this.l)?$CLJS.Wb(this.l):$CLJS.oe())};$CLJS.h.Z=function(){return this.C};$CLJS.h.ba=function(){return 1+$CLJS.D(this.l)};
$CLJS.h.X=function(){var a=this.B;return null!=a?a:this.B=a=-56613580^$CLJS.vc(this)};$CLJS.h.W=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.h(this.vb,b.vb)&&$CLJS.E.h(this.l,b.l)};$CLJS.h.ra=function(a,b){return $CLJS.fd(new $CLJS.R(null,new $CLJS.n(null,1,[$CLJS.Hn,null],null),null),b)?$CLJS.sh.h($CLJS.sb($CLJS.uf.h($CLJS.ie,this),this.C),b):new ro(this.vb,this.C,$CLJS.ke($CLJS.sh.h(this.l,b)),null)};
$CLJS.h.ma=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "schemas":return!0;default:return $CLJS.fd(this.l,b)}};$CLJS.h.V=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Hn,b):$CLJS.O.call(null,$CLJS.Hn,b))?new ro(c,this.C,this.l,null):new ro(this.vb,this.C,$CLJS.Cg.j(this.l,b,c),null)};$CLJS.h.$=function(){return $CLJS.y($CLJS.th.h(new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.gd($CLJS.Hn,this.vb)],null),this.l))};$CLJS.h.aa=function(a,b){return new ro(this.vb,b,this.l,this.B)};
$CLJS.h.ca=function(a,b){return $CLJS.Zc(b)?this.V(null,$CLJS.Pc(b,0),$CLJS.Pc(b,1)):$CLJS.qd($CLJS.Xa,this,b)};
$CLJS.h.Da=function(){var a=this;return $CLJS.Ql($CLJS.om,function(){return function d(c){return new $CLJS.Ld(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.ad(e)){var f=$CLJS.Rb(e),g=$CLJS.D(f),l=$CLJS.Od(g);a:for(var k=0;;)if(k<g){var m=$CLJS.Pc(f,k);m=new $CLJS.n(null,2,[$CLJS.Ll,po($CLJS.sm(m)),$CLJS.Cl,m],null);l.add(m);k+=1}else{f=!0;break a}return f?$CLJS.Rd($CLJS.Td(l),d($CLJS.Sb(e))):$CLJS.Rd($CLJS.Td(l),null)}l=$CLJS.A(e);return $CLJS.Jd(new $CLJS.n(null,2,[$CLJS.Ll,po($CLJS.sm(l)),
$CLJS.Cl,l],null),d($CLJS.qc(e)))}return null}},null)}(a.vb)}(),function(b){return new $CLJS.Dd(null,$CLJS.vn,new $CLJS.Dd(null,b,null,1,null),2,null)})};$CLJS.h.Ca=function(){return $CLJS.Jd($CLJS.yn,$CLJS.xg.h($CLJS.tm,this.vb))};$CLJS.to=$CLJS.Em($CLJS.ko,$CLJS.ue(function(a){return/^[\s\xa0]*$/.test(null==a?"":String(a))}),"Non-blank string");$CLJS.uo=$CLJS.Am(function(a){return $CLJS.si(a,$CLJS.oj)},"Valid field type");$CLJS.vo=$CLJS.Am(function(a){return $CLJS.si(a,$CLJS.Dj)||$CLJS.si(a,$CLJS.Uj)},"Valid semantic type");$CLJS.wo=$CLJS.Em($CLJS.no,function(a){return 0<a},"positive integer");$CLJS.xo=$CLJS.Em($CLJS.no,$CLJS.ue($CLJS.qo),"integer \x3e\x3d 0");$CLJS.yo=$CLJS.he([$CLJS.jo,$CLJS.jo]);
$CLJS.zo=$CLJS.so($CLJS.G([$CLJS.oo,$CLJS.ko]));