/* eslint-disable react/prop-types */
import React from "react";
import { t } from "ttag";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { moveElement } from "metabase/visualizations/lib/utils";

import ChartSettingFieldPicker from "./ChartSettingFieldPicker";
import { AddAnotherContainer } from "./ChartSettingFieldsPicker.styled";

const ChartSettingFieldsPicker = ({
  value: fields = [],
  options,
  onChange,
  addAnother,
  ...props
}) => {
  const handleDragEnd = ({ source, destination }) => {
    const oldIndex = source.index;
    const newIndex = destination.index;
    onChange(moveElement(fields, oldIndex, newIndex));
  };

  const calculateOptions = field => {
    return options.filter(
      option =>
        fields.findIndex(v => v === option.value) < 0 || option.value === field,
    );
  };

  return (
    <div>
      {Array.isArray(fields) ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((field, index) => {
                  return (
                    <Draggable
                      key={`draggable-${field}`}
                      draggableId={`draggable-${field}`}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="mb1"
                        >
                          <ChartSettingFieldPicker
                            {...props}
                            key={index}
                            value={field}
                            options={calculateOptions(field)}
                            onChange={updatedField => {
                              const fieldsCopy = [...fields];
                              // this swaps the position of the existing value
                              const existingIndex =
                                fields.indexOf(updatedField);
                              if (existingIndex >= 0) {
                                fieldsCopy.splice(
                                  existingIndex,
                                  1,
                                  fields[index],
                                );
                              }
                              // replace with the new value
                              fieldsCopy.splice(index, 1, updatedField);
                              onChange(fieldsCopy);
                            }}
                            onRemove={
                              fields.filter(field => field != null).length >
                                1 ||
                              (fields.length > 1 && field == null)
                                ? () =>
                                    onChange([
                                      ...fields.slice(0, index),
                                      ...fields.slice(index + 1),
                                    ])
                                : null
                            }
                            showDragHandle={fields.length > 1}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <span className="text-error">{t`error`}</span>
      )}
      {addAnother && (
        <AddAnotherContainer>
          <a
            className="text-brand text-bold py1"
            onClick={() => {
              const remaining = options.filter(
                o => fields.indexOf(o.value) < 0,
              );
              if (remaining.length === 1) {
                // if there's only one unused option, use it
                onChange(fields.concat([remaining[0].value]));
              } else {
                // otherwise leave it blank
                onChange(fields.concat([undefined]));
              }
            }}
          >
            {addAnother}
          </a>
        </AddAnotherContainer>
      )}
    </div>
  );
};

export default ChartSettingFieldsPicker;
